<template>
  <div class="container-fluid mt-3">
    <Form @submit="editUser" v-slot="{ errors }">
      <div class="d-flex mb-1 justify-content-between align-items-center flex-wrap">
        <Breadcrumb :parent_page="{title: 'المستخدمين' , path: '/users'}"  current_page="تعديل مستخدم"/>
        <div class="controls-btns d-flex gap-2 mb-2">
          <button class="btn btn-publish" type="submit" @click="editUser" :disabled="loading">حفظ</button>
        </div>
      </div>

      <UserDetails v-if="!loading" :errors="errors" @handleUpdate="handleOnChange" :handle-change="handleOnChange" :user="user"/>

      <el-card v-else class="custom-card">
        <Loader />
      </el-card>
    </Form>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import {Form} from "vee-validate";
import {updateUser, getUser} from "@/services/users";
import {useToast} from "vue-toastification";
import UserDetails from "@/views/users/components/userForm";
import Loader from "@/components/Loader";

export default {
  name      : "Edit_User",
  components: {
    Loader,
    UserDetails,
    Breadcrumb,
    Form,
  },
  data()
  {
    return {
      toast : useToast(),
      loading         : false,
      user            : {}
    }
  },
  mounted() {
    this.getUserData();
  },
  methods : {
    handleOnChange(key, val)
    {
      this.user = {
        ...this.user,
        [key]: val
      }
    },
    getUserData(){
      this.loading = true
      getUser(this.$route.params.user_id)
      .then(res=>{
        res.data.type_id = res.data?.roles[0]?.id || null
        this.user = res.data

        this.loading = false
      })
      .catch(()=>{
        this.loading = false
      })
    },
    async editUser()
    {
      try {
        this.loading = true;
        const result = await updateUser(this.user.id,this.user)
        if(result.data.success) {
          this.loading = false;
          this.toast.success("تم الحفظ المستخدم بنجاح", {
            position: "top-center",
          });
          await this.$router.push('/users');
        }
      }catch (e){
        this.toast.error("الرجاء التأكد من تعبئة كافة الحقول", {
          position: "top-center",
        });
        this.loading = false
        console.log(e)
      }
    },
  }
}
</script>

